module.exports = {
    associationName: `huizhangqun`,
    displayName: `huizhangqun`,
    siteUrl: `https://hzq.xiaoju.ca`, // Site domain. Do not include a trailing slash!
    folderName: `hzq`,
    themeName: `ghost`,
    authors: [
        `hzq`,
    ],
    systemMenu: true,
    postsPerPage: 3, // Number of posts shown on paginated pages (changes this requires sometimes to delete the cache)
    title: `会长群`,
    shortTitle: `会长群`, // Used for App manifest e.g. Mobile Home Screen
    siteIcon: `favicon.png`, // Logo in /static dir used for SEO, RSS, and App manifest
    siteTitleMeta: `huizhangqun`, // This allows an alternative site title for meta data for pages.
    siteDescriptionMeta: `huizhangqun(Canada)`, // This allows an alternative site description for meta data for pages.

    //only posts with these tags will be shown
    tags: [`event`,`people`,`about`],
    navigation: [
        { label: `Home`, url: `/` },
        { label: `Events`, url: `/event` },
        { label: `People`, url: `/people` },
    ],
    tagsToDisplay: [
        {
            node: {
                slug: `event`,
                tagTitle: `Events`,
                tagDescription: `欢迎加入缤纷多彩的活动`,
                url: `http://cms.xiaoju.ca/tag/event/`,
                postCount: 5,
            },
        },
        {
            node: {
                slug: `people`,
                tagTitle: `People`,
                tagDescription: `校友风采展现`,
                url: `http://cms.xiaoju.ca/tag/people/`,
                postCount: 2,
            },
        },
    ],
    twitter: ``,
    facebook: ``,
    lang: `en`,
    shareImageWidth: 1000, // Change to the width of your default share image
    shareImageHeight: 523, // Change to the height of your default share image

    backgroundColor: `#e9e9e9`, // Used for Offline Manifest
    themeColor: `#15171A`, // Used for Offline Manifest
}
